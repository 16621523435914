.techs {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 70%;
  box-shadow: 3px 2px 10px gray;
  min-height: 12rem;
  border-radius: 20px;
  padding: 3rem;
  flex-wrap: wrap;
  margin: 2rem;
  border: 1px solid gray;
  font-size: 1.2rem;
   font-weight: 600
}

@media screen and (max-width: 900px) {
  .techs{
    width: 90%;
    padding: 2rem;
    font-size: 1rem;


  }
}

@media screen and (max-width: 550px) {
  .techs{
    width: 100%;
    padding: 1rem;
    margin: 0.5rem;
  }
}